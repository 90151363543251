import React, {useEffect, useState}                 from 'react'
import Layout                                       from '../components/shared/layout'
import {graphql, Link}                              from 'gatsby'
import {NewsItem}                                   from '../../entities/barrister'
import {Avatar, Container, Grid, Theme, Typography} from '@material-ui/core'
import theme                                        from '../theme'
import PortableText                                 from '../components/portableText'
import {makeStyles}                                 from '@material-ui/core/styles'
import Moment                                       from 'moment'
import SanityImage                                  from 'gatsby-plugin-sanity-image'

export const query = graphql`
    query NewsItemQuery($id: String!) {
        news: sanityBlog(_id: { eq: $id }) {
            _rawBody(resolveReferences: {maxDepth: 5})
            title
            slug {
                current
            }
            publishedAt(formatString: "DD-MM-yyyy HH:mm")
            authors {
                author {
                    name
                    _rawBio(resolveReferences: {maxDepth: 5})
                    image {
                        ...ImageData
                    }
                }
            }
            mainImage {
                ...ImageData
            }
        }
    }
`

interface NewsItemPageProps {
    data: { news: NewsItem }
}

const useStyles = makeStyles((theme: Theme) => ({
    article: {maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto'},
    articleTitle: {fontWeight: 500, fontSize: '2.5rem', textAlign: 'center', lineHeight: '2.45rem'},
    headerContainer: {},
    byline: {},
    dateLine: {},
    bodyWrapper: {
        background: '#FFF'
    },
    bodyContainer: {
        backgroundColor: '#FFF',
        padding: '2em 0'
    },
    authorsContainer: {
        display: 'flex',
        flexFlow: 'row wrap'

    },
    link: {
        color: 'goldenrod',
        textDecoration: 'none',
        '&:hover': {
            color: '#000'
        }
    }
}))

const NewsItemPage = (props: NewsItemPageProps) => {

    const classes = useStyles(theme)
    const [newsItem, setNewsItem] = useState<NewsItem | undefined>()

    useEffect(() => {
        if (props.data.news) {
            setNewsItem(props.data.news)
        }
    }, [])

    const renderAuthors = (authors) => authors.map(({author}: any, idx) =>
        <Grid container alignContent={'center'} alignItems={'center'} spacing={1} className={classes.authorsContainer}
              key={idx}>
            <Grid item>
                {
                    author.image && (
                                     <Avatar alt={author.image ? author.image.alt : 'Placeholder Avatar Image'}
                                             src={author.image.asset.url}/>
                                 )
                }
            </Grid>
            <Grid item>
                <Typography variant={'subtitle1'}
                            component={'span'}>
                    {author.name}
                </Typography>
            </Grid>
        </Grid>
    )


    return (
        <Layout helmetData={{
            pageTitle: newsItem ? newsItem.title : '',
            metaDescription: '',
            slug: newsItem ? newsItem.slug.current : ''
        }} backgroundImage={''}>
            {
                newsItem && (
                             <header className={classes.headerContainer}>
                                 <SanityImage
                                     height={1200}
                                     {...newsItem.mainImage}
                                     style={{
                                         width: '100%',
                                         height: '100%',
                                         objectFit: 'cover',
                                         maxHeight: '400px',
                                         boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
                                     }}
                                     imgStyle={{
                                         maxHeight: '400px',
                                         position: 'absolute',
                                         left: '0',
                                         right: '0',
                                         marginLeft: 0,
                                         marginRight: 0
                                     }}/>
                             </header>
                         )
            }
            <Container style={{backgroundColor: '#FFF'}} maxWidth={'md'}>
                <section>
                    {
                        newsItem && (
                                     <section className={classes.bodyContainer}>
                                         <article
                                             className={classes.article}>
                                             <Typography variant={'h1'} component={'h1'} className={classes.articleTitle}>
                                                 {newsItem.title}
                                             </Typography>
                                             <Grid container justify={'space-between'} alignItems={'center'}
                                                   alignContent={'center'} spacing={3}>
                                                 <Grid item>
                                                     {newsItem.authors && renderAuthors(newsItem.authors)}
                                                 </Grid>
                                                 <Grid item>
                                                     <p className={classes.dateLine}>
                                                         {Moment(newsItem.publishedAt, 'DD-MM-YYYY HH:mm').format('D MMMM YYYY, HH:mm')}
                                                     </p>
                                                 </Grid>
                                             </Grid>
                                             <PortableText blocks={newsItem._rawBody}/>
                                         </article>
                                         <Link className={classes.link} to={'/news'}>
                                             &larr; Back to News
                                         </Link>
                                     </section>
                                 )
                    }
                </section>
            </Container>
        </Layout>
    );
}

export default NewsItemPage
